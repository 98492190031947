let center = [51.59838706015326,46.00654138161465];

function init() {
	let map = new ymaps.Map('map-test', {
		center: center,
		zoom: 17
	});

	let placemark = new ymaps.Placemark(center, {
		balloonContent: `

			<div class="balloon">
				<div class="balloon__address">"Sherstneva"<br>Саратов, ул. Блинова 52В</div>
				<div class="balloon__contacts">
					<a href="tel:+79053234811">8 (905) 323-48-11</a>
				</div>
			</div>

		`
	}, {
		iconLayout: 'default#image',
		iconImageHref: './img/marker.svg',
		iconImageSize: [35, 35],
		iconImageOffset: [-19, -44]
	});

	map.controls.remove('geolocationControl'); // удаляем геолокацию
  map.controls.remove('searchControl'); // удаляем поиск
  map.controls.remove('trafficControl'); // удаляем контроль трафика
  map.controls.remove('typeSelector'); // удаляем тип
  map.controls.remove('fullscreenControl'); // удаляем кнопку перехода в полноэкранный режим
  map.controls.remove('zoomControl'); // удаляем контрол зуммирования
  map.controls.remove('rulerControl'); // удаляем контрол правил
  map.behaviors.disable(['scrollZoom']); // отключаем скролл карты (опционально)

	map.geoObjects.add(placemark);

	placemark.balloon.open();

}

ymaps.ready(init);


